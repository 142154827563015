import React from 'react';
import styles from './Compliance.module.scss';
import { RichText } from 'prismic-reactjs';
import { object, array } from 'prop-types';
import Image from '../../../../components/Image/Image';

const Compliance = ({ primary, items }) => {
  const { title, description, mainimg } = primary;

  return (
    <div className={styles.compliance}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.title}>
            <RichText render={title.richText} />
          </div>
          <div className={styles.countries}>
            {items?.map((imag, index) => {
              return (
                <Image
                  className={styles.image}
                  image={imag?.countryimg}
                  key={index}
                />
              );
            })}
          </div>
          <div className={styles.descr}>
            <RichText render={description.richText} />
          </div>
        </div>
        <div className={styles.imageWrapper}>
          <Image className={styles.image} image={mainimg} />
        </div>
      </div>
    </div>
  );
};

Compliance.propTypes = {
  primary: object,
  items: array,
};

export default Compliance;
