import React from 'react';
import styles from './LimitlessDesign.module.scss';
import { RichText } from 'prismic-reactjs';
import { object, array } from 'prop-types';
import Image from '../../../../components/Image/Image';

const LimitlessDesign = ({ primary }) => {
  const { title, description, image } = primary;

  return (
    <div className={styles.limitlessDesign}>
      <div className={styles.container}>
        <div className={styles.imageWrapper}>
          <Image className={styles.image} image={image} />
        </div>
        <div className={styles.content}>
          <div className={styles.title}>
            <RichText render={title.richText} />
          </div>
          <div className={styles.descr}>
            <RichText render={description.richText} />
          </div>
        </div>
      </div>
    </div>
  );
};

LimitlessDesign.propTypes = {
  primary: object,
  items: array,
};

export default LimitlessDesign;
