import React from 'react';
import styles from './DifferentFeatures.module.scss';
import { RichText } from 'prismic-reactjs';
import { object, array } from 'prop-types';
import Image from '../../../../components/Image/Image';

const DifferentFeatures = ({ primary, items }) => {
  const { title } = primary;

  return (
    <div className={styles.differentFeatures}>
      <div className={styles.container}>
        <div className={styles.title}>
          <RichText render={title.richText} />
        </div>
        <div className={styles.featureType}>
          {items?.map((item, index) => {
            return (
              <div className={styles.feature} key={index}>
                <Image image={item.image} />
                <div className={styles.subTitle}>
                  <RichText render={item.subtitle.richText} />
                </div>
                <div className={styles.descr}>
                  <RichText render={item.description.richText} />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

DifferentFeatures.propTypes = {
  primary: object,
  items: array,
};

export default DifferentFeatures;
